import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
import { stringToPrice } from "../../../utils/navigateToCart"
import { isLoadedScript } from "../../../utils/additionalScriptHelpers"

const MatelasAlmaWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: ${({ page }) => (page === "oreiller" ? "0px" : "10px 0px 0px")};
  min-height: 9vh;

  @media (max-width: 720px) {
    margin: 10px 0px 0px;
  }
`

const AlmaWidgetBox = styled.div`
  max-width: 100%;
  background: #fff;

  .alma-payment-plans-container {
    width: 100%;
    max-width: ${({ page }) =>
      page === "solution-textile" ? "360px" : "100%"};
    border: 1px solid #b0b1c2;
    border: 1px solid #262626;
    border-radius: 5px;
    min-height: 81px;

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
`

const MatelasAlmaSection = ({ intl, price, page }) => {
  const [almaWidget, setalmaWidget] = useState(null)

  const almaWidgetScript = () => {
    let cssUrl =
      "https://cdn.jsdelivr.net/npm/@alma/widgets@3.x/dist/widgets.min.css"
    let srcUrl =
      "https://cdn.jsdelivr.net/npm/@alma/widgets@3.x/dist/widgets.umd.js"
    if (!isLoadedScript(srcUrl)) {
      const script = document.createElement("script")
      script.src = srcUrl
      script.type = "text/javascript"
      document.head.appendChild(script)

      const cssScript = document.createElement("link")
      cssScript.href = cssUrl
      cssScript.type = "text/css"
      cssScript.rel = "stylesheet"
      document.head.appendChild(cssScript)
    }
  }

  const almaPromise = () => {
    return new Promise((resolve, reject) => {
      almaWidgetScript()
      let timeout = setInterval(() => {
        if (window.Alma) {
          clearInterval(timeout)
          resolve(window.Alma)
        }
      }, 200)
    })
  }

  const almaInit = () => {
    const Alma = window.Alma || {}

    var widgets = Alma.Widgets?.initialize(
      "11jMSdD4dVo6BI2vYqyAUGMqi2qwN2j7xJ",
      Alma.ApiMode.LIVE
    )
    setalmaWidget(widgets)
    almaWidgetAddPaymentPlan(widgets)
  }

  const almaWidgetAddPaymentPlan = widgets => {
    let newPrice = Number(stringToPrice(price) * 100).toFixed(0)
    newPrice = Number(newPrice)

    widgets?.add(Alma.Widgets.PaymentPlans, {
      container: "#alma-widget",
      locale: intl.locale,
      hideIfNotEligible: true,
      purchaseAmount: newPrice,
      monochrome: false,
      plans: [
        {
          installmentsCount: 2,
          minAmount: 9900,
          maxAmount: 500000,
        },
        {
          installmentsCount: 3,
          minAmount: 9900,
          maxAmount: 500000,
        },
        {
          installmentsCount: 4,
          minAmount: 40000,
          maxAmount: 500000,
        },
        {
          installmentsCount: 10,
          minAmount: 40000,
          maxAmount: 500000,
        },
      ],
    })
  }

  useEffect(() => {
    if (isBrowser) {
      almaPromise().then(() => {
        almaInit()
      })
    }
  }, [])

  useEffect(() => {
    almaInit()
    almaWidgetAddPaymentPlan(almaWidget)
  }, [price])
  return (
    price && (
      <MatelasAlmaWrapper id="alma_section" page={page}>
        <AlmaWidgetBox page={page} id="alma-widget" />
      </MatelasAlmaWrapper>
    )
  )
}

export default injectIntl(MatelasAlmaSection)

import React from "react"
import styled from "@emotion/styled"
import ItemCarousel from "../ItemCarousel"
import { injectIntl } from "gatsby-plugin-intl"

const ProductImageSliderWrapper = styled.div`
  position: relative;
`

export const ProductImageSlider = ({
  intl,
  data,
  medicaleLogo,
  page,
  fullScreen,
  imageSection,
  newTag,
  video_text,
  video,
  textVisible,
}) => (
  <ProductImageSliderWrapper>
    {data && data.length > 0 && (
      <ItemCarousel
        data={data}
        medicaleLogo={medicaleLogo}
        page={page}
        imageSection={imageSection}
        newTag={newTag}
        videoText={video_text}
        video={video}
        textVisible={textVisible}
      />
    )}
  </ProductImageSliderWrapper>
)

export default injectIntl(ProductImageSlider)

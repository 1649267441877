import React from "react"
import styled from "@emotion/styled"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import {
  ProductPrice,
  ExclusifPrice,
  StrikePrice,
  ExclusifAddToCart,
  AddToCartWrapper,
} from "../styled"
import theme from "../../../utils/theme"

const ExclusifLyneupStickyAddToCart = ({
  intl,
  title,
  offerText,
  cart_text,
  handleAddTocart,
  active,
  setopenMobileAddToCartModal,
  id,
  discountPrice,
  regularPrice,
  page,
}) => (
  <Wrapper id={id} active={active}>
    <StickyBottom>
      <LeftWrapper>
        <LeftTopWrapper>
          <TitleWrapper>
            {offerText && discountPrice != "129" && (
              <OfferTitle
                dangerouslySetInnerHTML={{
                  __html: offerText.offer_title,
                }}
                page={page}
              />
            )}
            <StickyProductSingleTitle
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </TitleWrapper>
        </LeftTopWrapper>
      </LeftWrapper>
      <RightWrapper>
        {discountPrice && (
          <ProductPrice
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ExclusifPrice page={page}>
              {`${
                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                  ? process.env.GATSBY_CURRENCY
                  : ""
              }${discountPrice}${
                process.env.GATSBY_CURRENCY_TYPE === "eur"
                  ? process.env.GATSBY_CURRENCY
                  : ""
              }`}
            </ExclusifPrice>
            {discountPrice != "129" && (
              <StrikePrice page={page}>
                {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                  ? process.env.GATSBY_CURRENCY
                  : ""}
                {regularPrice}
                {process.env.GATSBY_CURRENCY_TYPE === "eur"
                  ? process.env.GATSBY_CURRENCY
                  : ""}
              </StrikePrice>
            )}
          </ProductPrice>
        )}

        <AddToCartWrapper style={{ marginLeft: 10, flex: 1 }}>
          <ExclusifAddToCart
            page={page}
            onClick={() => {
              setopenMobileAddToCartModal && setopenMobileAddToCartModal(true)
              handleAddTocart && handleAddTocart()
            }}
          >
            {cart_text}
          </ExclusifAddToCart>
        </AddToCartWrapper>
      </RightWrapper>
    </StickyBottom>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  opacity: ${({ active }) => (active ? 1 : 0)};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px 20px;
  width: 100%;
  background: white;
  z-index: 3;
  box-shadow: 0 0 15px 2px #cecece80;
  transition: visibility 0s, opacity 0.5s linear;
`

const StickyBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
  width: 100%;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    display: none;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    flex: 1;
    justify-content: space-between;
  }
`

const LeftTopWrapper = styled.div`
  display: flex;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const OfferTitle = styled.div`
  background: ${({ page }) =>
    page === "enplus"
      ? theme.colors.primary.enplus
      : theme.colors.primary.exclusif};
  padding: 8px 15px;
  font-size: 24px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 10px 0px;
  margin-right: 15px;

  @media (max-width: 1150px) {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    font-size: 21px;
  }

  @media (max-width: 850px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const StickyProductSingleTitle = styled.div`
  text-transform: uppercase;
  color: #262626;
  font-size: 15px;
  line-height: 24px;
  font-family: "Gotham Book";
  font-weight: bold;
  font-style: normal;

  @media (max-width: 850px) {
    font-size: 13px;
  }

  @media (max-width: 768px) {
    font-size: 11px;
  }
`

export default injectIntl(ExclusifLyneupStickyAddToCart)
